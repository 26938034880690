// CustomerReviews.tsx
import React from "react";
import "./CustomerReviews.css"; // Import the CSS for styling

const CustomerReviews: React.FC = () => {
    const reviews = [
        {
            id: 1,
            name: "John Souvestre",
            review: "Exactly what I was looking for.  Gets the job done and simple to set up.  Thank you!",
            rating: 5,
        },
        {
            id: 2,
            name: "SARAF TARSEWALA",
            review: "option to increase image size should be included rest good",
            rating: 4,
        },
        {
            id: 3,
            name: "Vinicio Reyes",
            review: "its a neat extension but it goes away if you dont have google.com typed into the address bar.. like when you open a new tab its just the plain google logo",
            rating: 3,
        },
    ];

    return (
        <div className="container mt-5">
            <h2 className="text-center section-title">Customer Reviews</h2>
            <div className="row">
                {reviews.map((review) => (
                    <div className="col-md-4 mb-4" key={review.id}>
                        <div className="review-card shadow">
                            <div className="review-header">
                                <h5 className="reviewer-name">{review.name}</h5>
                                <div className="rating">
                                    {Array.from({ length: 5 }, (_, index) => (
                                        <span key={index} className={index < review.rating ? "star filled" : "star"}>
                                            ★
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <p className="review-text">{review.review}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomerReviews;
