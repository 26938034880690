import React from 'react';
import ProductPage from './pages/ProductPage';

function App() {
  return (
    <ProductPage/>
  );
}

export default App;
