import React from "react";
import { useSpring, animated } from "@react-spring/web"; // Using latest react-spring
import "./ProductPage.css";
import CustomerReviews from "./CustomerReviews";

const ProductPage: React.FC = () => {
  // React Spring Animations
  const fadeInHero = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 200 });
  const scaleInFeature = useSpring({ transform: 'scale(1)', from: { transform: 'scale(0.9)' }, delay: 300 });
  const fadeInCTA = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 600 });

  // How It Works Steps
  const howItWorksSteps = [
    {
      id: 1,
      title: "Step 1: Install MyDoodle",
      description: "Click the install button to add MyDoodle to your Chrome browser.",
      icon: "fa-download",
      direction: "Install"
    },
    {
      id: 2,
      title: "Step 2: Customize Logo",
      description: "Choose to display your name, an image, or a clock in place of the Google logo.",
      icon: "fa-paint-brush",
      direction: "Customize"
    },
    {
      id: 3,
      title: "Step 3: Enjoy Your Search",
      description: "Start browsing Google with your personalized logo and have fun!",
      icon: "fa-smile",
      direction: "Enjoy"
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <header className="hero-section">
        <animated.div style={fadeInHero} className="hero-content">
          <h1>Customize Google with MyDoodle</h1>
          <p>
            Replace the Google logo with your name, an image, or a clock. Join 2k+ active users and make Google more personal with MyDoodle.
          </p>
          <animated.div style={fadeInCTA}>
            <a
              href="https://chromewebstore.google.com/detail/my-doodle/acnonhmkejidodnppipkffhfjbfiogha"
              className="btn btn-danger btn-lg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-download" aria-hidden="true"></i> Install MyDoodle
            </a>
          </animated.div>
          <p className="install-text">It's lightweight and easy to use!</p>
        </animated.div>
      </header>

      {/* Features Section */}
      <section className="features">
        <div className="container">
          <h2 className="section-title">Key Features</h2>
          <div className="row feature-row">
            {/* Feature 1 */}
            <animated.div style={scaleInFeature} className="col-md-4 col-sm-12 my-4">
              <div className="feature-card">
                <div className="icon">
                  <i className="fa fa-user" aria-hidden="true"></i>
                </div>
                <h3>Personalize with Your Name</h3>
                <p>Replace the Google logo with your name you choose. Your search page, your identity.</p>
              </div>
            </animated.div>

            {/* Feature 2 */}
            <animated.div style={scaleInFeature} className="col-md-4 col-sm-12 my-4">
              <div className="feature-card">
                <div className="icon">
                  <i className="fa fa-image" aria-hidden="true"></i>
                </div>
                <h3>Add Any Image</h3>
                {/* <p>Upload any image and make it the Google logo. Add a personal touch to every search you make.</p> */}
                <p>Enter any image URL you want to use on the Search Page. Add a personal touch to every search you make.</p>
              </div>
            </animated.div>

            {/* Feature 3 */}
            <animated.div style={scaleInFeature} className="col-md-4 col-sm-12 my-4">
              <div className="feature-card">
                <div className="icon">
                  <i className="fa fa-clock" aria-hidden="true"></i>
                </div>
                <h3>Display a Clock</h3>
                <p>Turn the Google logo into a live clock and stay on track with time while you browse.</p>
              </div>
            </animated.div>
          </div>
        </div>
      </section>

      {/* User Statistics Section */}
      <section className="user-stats text-center">
        <div className="container">
          <h2 className="section-title">Join 2k+ Active Users</h2>
          <p>MyDoodle is lightweight and trusted by thousands of users to enhance their browsing experience with no impact on performance.</p>
          <animated.div style={fadeInCTA}>
            <a
              href="https://chromewebstore.google.com/detail/my-doodle/acnonhmkejidodnppipkffhfjbfiogha"
              className="btn btn-primary btn-lg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-download" aria-hidden="true"></i> Install MyDoodle Now
            </a>
          </animated.div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works">
        <div className="container">
          <h2 className="section-title">How It Works</h2>
          <div className="row">
            {howItWorksSteps.map(step => (
              <div key={step.id} className="col-md-4 col-sm-12">
                <div className="step-card">
                <div className="icon">
                  <i className={`fa ${step.icon}`} aria-hidden="true"></i>
                </div>
                <h3>{step.title}</h3>
                <p>{step.description}</p>
                <p className="step-direction">{step.direction}</p>
              </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Video Section */}
      <section className="video-section text-center">
        <h2 className="section-title">Watch How It Works</h2>
        <iframe 
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/dYEJq-sbyB4" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </section>

      <CustomerReviews/>


      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <p>&copy; {new Date().getFullYear()} MyDoodle. All rights reserved.</p>
          <ul className="footer-links">
            <li><a href="https://www.laar.pro/privacy">Privacy Policy</a></li>
            <li><a href="https://www.laar.pro/terms">Terms & Conditions</a></li>
            <li><a href="https://www.laar.pro/contact">Contact Us</a></li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default ProductPage;
